/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.epit-container {
    padding: 10px 20px;
}

.slider {
    .swiper-pagination {
        display: inline-block;
        width: unset !important;
        margin: auto !important;
        left: calc(50%) !important;
        transform: translateX(-50%) scale(75%);
        background: var(--ion-color-white);
        border-radius: 10px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
    }

    .logo {
        width: 60px;
        margin: auto;
        position: relative;
        z-index: 999;
        border-radius: 100%;
        border: 2px solid var(--ion-color-white);
        height: 62px;
        background: var(--ion-color-white);
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: var(--ion-color-custom-thr-red) !important;
    }

    .swiper-pagination-bullet {
        background: var(--ion-color-medium-tint) !important;
        opacity: 1 !important;
    }
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: exo;
    src: url("/assets/fonts/Exo-VariableFont_wght.ttf");
}

@font-face {
    font-family: Yanone Kaffeesatz;
    src: url("/assets/fonts/YanoneKaffeesatz-Regular.ttf");
}

@font-face {
    font-family: Lobster;
    src: url("/assets/fonts/Lobster-Regular.ttf");
}



@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/Poppins/Poppins-SemiBold.eot');
    src: url('/assets/fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
        url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/Poppins/Poppins-Regular.eot');
    src: url('/assets/fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('/assets/fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




body {
    font-family: exo;
}

.custom-popup {
    .modal-wrapper {
        --background: transparent;
        background: transparent !important;
    }
}

.alert.form-error {
    div {
        text-align: right;
        font-size: 12px;
        color: var(--ion-color-custom-six-red);
        margin-top: 3px;
    }
}

.error-box {
    width: 100%;
    margin: 10px auto;
    background: var(--ion-color-custom-nin-red);
    color: var(--ion-color-white);
    border-radius: 5px;
    text-align: center;
    padding: 10px 0px 12px 00px;
    font-size: 11px;
    font-family: exo;
    font-weight: normal;

    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid var(--ion-color-white);
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid var(--ion-color-white);
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.success-box {
    /*
  width: 100%;
  padding: 10px 10px 18px 10px;
  margin: 10px auto;
  background: var(--ion-color-custom-sec-green);
  color: var(--ion-color-white);
  border-radius: 5px;
  font-size: 13px;
  text-align: center;*/
    width: 100%;
    padding: 10px 0px 12px 00px;
    margin: 10px auto;
    background: var(--ion-color-custom-sec-green);
    color: var(--ion-color-white);
    border-radius: 5px;
    font-size: 11px;
    text-align: center;
    font-family: exo;
    font-weight: normal;

    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid var(--ion-color-white);
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid var(--ion-color-white);
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.pac-logo:after {
    display: none !important;
}

.pac-container {
    background-color: var(--ion-color-sec-white);
}

.pac-item {
    padding: 5px;
}

.pointer {
    cursor: pointer;
}

.ion-toolbar {
    position: relative;
    margin-top: -25px;

    .ion-title {
        text-align: left;
        padding: 40px 20px 15px 20px !important;
        background: var(--ion-color-custom-header);
        color: var(--ion-color-white);
    }

    .large-title {
        display: block;
        font-size: 18px;
        font-weight: bold;

        ion-icon {
            font-size: 23px;
            margin-bottom: -5px;
        }
    }
}

ion-header {
    background-color: var(--ion-color-white);
}

.cart-button {
    z-index: 99;
    display: inline-block;
    padding: 10px;
    position: fixed;
    bottom: 120px;
    right: 20px;
    background: var(--ion-color-custom-red);
    color: var(--ion-color-white);
    border-radius: 150px;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(40deg);
    border: 4px dashed var(--ion-color-white);
    transition: 0.5s;

    ion-icon {
        font-size: 25px !important;
        // color: var(--ion-color-white);
    }

    span {
        font-weight: 600;
        font-size: 10px;
        display: block;
        text-align: center;
    }
}

.cart-button.cart-top {
    bottom: calc(100% - 130px);
    transform: rotate(0deg);
}

.cart-button.animating {
    z-index: 99;
    display: inline-block;
    padding: 10px;
    position: fixed;
    top: unset;
    bottom: calc(50% - 25px) !important;
    right: calc(50% - 25px) !important;
    background: var(--ion-color-custom-red);
    color: var(--ion-color-white);
    border-radius: 150px;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(0deg);
    border: none;

    &::before {
        content: " ";
        width: 75px;
        height: 75px;
        display: block;
        position: absolute;
        left: -3px;
        top: -3px;
        border-radius: 100%;
        border: 3px dashed var(--ion-color-white);
        animation: cart-animation-1 1.5s infinite linear;
    }

    ion-icon {
        opacity: 1;
        animation: cart-animation-2 1.5s linear;
    }

    span {
        font-weight: 600;
        font-size: 12px;
        display: block;
        text-align: center;
    }
}

.red-button {
    background: var(--ion-color-custom-thr-red);
    padding: 15px 20px;
    border-radius: 5px;
    color: var(--ion-color-white);
    min-width: 250px;
    font-size: 18px;
    ;
}

.text-center {
    text-align: center !important;
}

.red-color {
    color: var(--ion-color-custom-eig-red);
}

green-color {
    color: var(--ion-color-custom-green);
}

.ion-back-opacity {
    opacity: 0.5;
}

@keyframes cart-animation-1 {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(90deg) scale(1);
    }

    100% {
        transform: rotate(180deg) scale(1);
    }
}

@keyframes cart-animation-2 {
    0% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }

    49.9% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }

    50% {
        transform: translateY(-100px) scale(1);
        opacity: 0;
    }

    75% {
        padding-bottom: 0;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    80% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }

    85% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    90% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }

    95% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    100% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

.submit-buttons {
    background: var(--ion-color-custom-fif-red);
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: #f9f9f9;
    box-shadow: 0 1px 4px 1px var(--ion-color-custom-thr-gray);
    font-weight: bold;
    font-size: 12px;

    ion-icon {
        font-size: 20px;
        margin-bottom: -5px;
        margin-right: 10px;
    }

    .text {
        font-size: 12px !important;
        text-transform: full-size-kana;
    }
}

.submit-buttons:disabled {
    background: var(--ion-color-custom-thr-gray);
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: var(--ion-color-custom-gray);
    box-shadow: 0 0px 0px 0px var(--ion-color-custom-thr-gray);
    font-weight: bold;
    font-size: 12px;
}

.sub-items {
    font-size: 13px;
    color: #79797996;
    padding-left: 5px;

    ul {
        padding-left: 5px !important;

        li {
            list-style: none;
        }
    }
}

.pb100 {
    padding-bottom: 100px;
}

// padding left 0
.pl-0 {
    padding-left: 0px !important;
}

.height-auto {
    height: auto !important;
}

.text-red {
    color: var(--ion-color-custom-red) !important;
}

.find-nearby-back-button {
    top: 7px !important;
    position: absolute !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.checkout-select {
    .alert-head {
        display: block;
        width: 100%;
        position: relative;
        background: var(--select-list-primary);
        font-weight: 600;
        padding: 5px;

        h2 {
            font-size: 17px;
            color: var(--alert-header-text-color);
            margin: 0 !important;
            padding: 5px 0;
        }

        .alert-wrapper.sc-ion-alert-ios {
            border-radius: 5px;
        }
    }

    .alert-wrapper.sc-ion-alert-ios {
        border-radius: 5px;
    }

    .alert-message.sc-ion-alert-ios {
        padding-bottom: 0 !important;
    }

    .alert-button.sc-ion-alert-md {
        border-radius: 2px;
        position: relative;
        background-color: transparent;
        color: var(--select-list-primary);
        font-weight: 500;
        text-align: end;
        text-transform: uppercase;
        overflow: hidden;
    }

    .alert-radio-label.sc-ion-alert-ios {
        color: var(--ion-color-custom-fou-gray);
    }

    .alert-button.sc-ion-alert-ios {
        color: var(--select-list-primary);
        font-size: 15px;
    }

    [aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
        border-color: var(--select-list-primary);
    }

    [aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
        color: var(--select-list-primary);
        font-weight: bold;
        font-size: 15px;
    }
}

.m-0 {
    margin: 0px !important;
}

.float-right {
    float: right !important;
}

.web-footer {
    background-color: var(--ion-color-custom-gray);
    padding: 20px 0;

    .footer-left {
        text-align: left;

        ul {
            list-style: none;
            display: inline-block;
            margin: 0;

            li {
                display: inline;

                a {
                    color: var(--ion-color-white);
                    text-decoration: none;
                    margin: 5px;
                }
            }
        }
    }

    .footer-right {
        color: var(--ion-color-white);
        text-align: right;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.not-found {
    width: 80%;
    text-align: center;
    margin-top: 40%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 20px;
    color: var(--ion-color-custom-medium-gray);

    ion-icon {
        font-size: 40px;
    }
}

.page-bottom-padding-fix {
    padding-bottom: 200px;
}

.text-right {
    text-align: right;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;

    &.green-dot {
        background-color: var(--ion-color-custom-green);
    }

    &.orange-dot {
        background-color: var(--ion-color-custom-yellow);
    }

    &.red-dot {
        background-color: var(--ion-color-custom-eig-red);
    }
}

.text-green {
    color: var(--ion-color-custom-green);
}

.text-red {
    color: var(--ion-color-custom-eig-red);
}

.text-orange {
    color: var(--ion-color-custom-yellow);
}

.modal-wrapper {
    border-radius: 10px !important;
}

.remove-position-absolute {
    position: relative !important;
}

/*
  uncomment while build ios
ion-header{
  margin-top: 40px !important;
}
*/

.ion-spinner-red {
    color: var(--ion-color-custom-thr-red) !important;
}

.ion-spinner-white {
    color: var(--ion-color-white) !important;
}

ion-select {
    .select-icon-inner {
        left: -5px !important;
        margin-top: -7px !important;
        border-top: 9px solid !important;
        border-right: 6px solid transparent !important;
        border-left: 6px solid transparent !important;
    }
}

.main-container-for-dishes {
    p {
        margin: 5px;
        margin-left: 10px;
        font-size: 14px;
        color: var(--ion-color-custom-fou-gray);
        position: relative;
        padding-left: 10px;

        &::after {
            content: " ";
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            top: 4px;
            left: -3px;
            transform: translateY(-50%);
            background: var(--ion-color-fou-white);
            border-radius: 100%;
        }
    }
}

.small-spinner-inputs {
    width: 15px !important;
    height: 15px !important;
    color: var(--ion-color-custom-eig-red);
}

.no-padding {
    padding: 0px !important;
}

.custom-input-label {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.custom-input-value {
    font-size: 14px !important;
}

.pull-right {
    float: right !important;
}

.min-width-100p {
    min-width: 100% !important;
}

.header-height {
    height: 25px;
}

.reservation-success-msg {
    font-size: 12px !important;
}

.reservation-success-msg-web {
    font-size: 18px !important;
    line-height: 1.5 !important;
}

.height-300-px {
    height: 300px !important;
}

.find-my-location-btn {
    background: var(--find-my-location-btn-background);
    padding: 15px 20px;
    border-radius: 5px;
    color: var(--ion-color-white);
    min-width: 250px;
    font-size: 18px;
    margin-top: 10px;

}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.tour-class {
    ion-backdrop {
        --backdrop-opacity: 0.8;
        pointer-events: none;
    }
}

.postal-item {
    margin-bottom: 5px;
    --background: #f3f3f3;
}

.app-version-no {
    font-size: 12px;
    color: white;
    position: absolute;
    bottom: 2px;
    font-weight: bold;
    left: 5px;
    background: black;
    padding: 0px 3px;
    border-radius: 4px;
}